import { Button } from 'antd'
import React, { useState } from 'react'

function SignUp(props) {
    const [visible, setVisible] = useState(false);
    return (
        <div className='container pt-3 pb-3'>
            <h3 className='mbtBlue'><strong>Join Us</strong></h3>
            <p>Join our mailing list to receive news, updates, and special offers.</p>
            {props.userType === "him" || props.userType === "them" ? <><Button id='sign' onClick={() => {
                document.getElementById('sign').style.display = "none"
                setVisible(true)
            }}>Sign Up</Button>
            {visible ? <iframe src={props.userType === "him" ? "https://docs.google.com/forms/d/e/1FAIpQLSfYUz9ldZvAWYYaNM9J021pZq_o0mbuGZcEFy4VWT_TFgK_HQ/viewform?embedded=true"
                : props.userType === "them" ? "https://docs.google.com/forms/d/e/1FAIpQLSe_u4domXSRi-qlG-tLkM6g08TedfgQpN7h_hcVK2Dj8gxIKA/viewform?embedded=true"
                    : ""} width="100%" height="914" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe> : ""}</>
                    : <>
                    <a href="https://squareup.com/outreach/r4vwln/subscribe" target="_blank">
                        <Button>Sign Up</Button>
                        </a>
                    </>}
        </div>
    )
}

export default SignUp