import React from 'react'
import { Link, useParams } from 'react-router-dom';
import her from '../assets/woman (1).png';
import him from '../assets/man (1).png';
import them from '../assets/couple (1).png';
import preg from '../assets/pregnant.png';

function Choice({ match }) {
  const check = useParams();
  localStorage.setItem("city", check.cityName);
  const city = localStorage.getItem("city")
  return (
    <div className='container for pt-5 pb-5'>
      <div className='row pb-5'>
        <div className='col-sm-12 d-flex justify-content-around mbtYellow'>
          <div className='bxs ps-4 pe-4 p-2'>
            <Link to={`/${city}/her`} className='mbtYellow' style={{textDecoration: "none"}}>
            <img src={her} width='50'/>
            <p className='pt-2'>Her</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='row pb-5'>
        <div className='col-sm-12 d-flex justify-content-around mbtYellow'>
          <div className='bxs ps-4 pe-4 p-2'>
          <Link to={`/${city}/pregnancy-massage`} className='mbtYellow' style={{textDecoration: "none"}}>
            <img src={preg} width='50'/>
            <p className='pt-2'>Her</p>
            </Link>
            </div>
        </div>
      </div>
      {/* <div className='row pt-5'>
        <div className='col-sm-12 d-flex justify-content-around mbtYellow'>
          <div className='bxs ps-4 pe-4 p-2'>
            <Link to={`/${city}/him`} className='mbtYellow' style={{textDecoration: "none"}}>
            <img src={him} width='50'/>
            <p className='pt-2'>Him</p>
            </Link>
          </div>
          <div className='bxs ps-4 pe-4 p-2'>
            <Link to={`/${city}/them`} className='mbtYellow' style={{textDecoration: "none"}}>
            <img src={them} width='50'/>
            <p className='pt-2'>Them</p>
            </Link>
            </div>
        </div>
      </div> */}
    </div>
  )
}

export default Choice