import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Content from '../components/Content';
import DefaultLayout from '../components/DefaultLayout'
import SignUp from '../components/SignUp';

function Them() {
    const city = useParams();
    useEffect(()=>{
      if (city.cityName === "SanAntonio")
      document.title ="San Antonio's Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy";
      else
      document.title = `${city.cityName}'s Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy`;
    },[]);
  return (
    <DefaultLayout city={city.cityName} userType="them">
    <Content userType="them"/>
    <div>
        <h5>Please <a href='#contact' className='mbtYellow'><u>contact</u></a> us for booking options</h5>
    </div>
    <hr/>
    <SignUp userType="them"/>
    </DefaultLayout>
  )
}

export default Them