import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Content from '../components/Content';
import DefaultLayout from '../components/DefaultLayout'
import SignUp from '../components/SignUp';

function Him() {
    const city = useParams();
    useEffect(()=>{
      if (city.cityName === "SanAntonio")
      document.title ="San Antonio's Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy";
      else
      document.title = `${city.cityName}'s Home for Pregnant Women's Tantric, Yoni & Perineal Massage | Mobile Body Therapy`;
    },[]);
  return (
    <DefaultLayout city={city.cityName} userType="him">
    <h2>Coming Soon</h2>
    <p>Please sign up for updates on MBT HIM</p>
    <hr/>
    <SignUp userType="him"/>
    </DefaultLayout>
  )
}

export default Him