import React, { useEffect, useState } from 'react'
import { Tabs, Button, ConfigProvider } from 'antd';
import tantric from '../assets/shutterstock_1093426625.jpg';
import relax from '../assets/shutterstock_781802926.jpg';
import consult from '../assets/consult.png';
import mantant from '../assets/mantantra.jpg';
import manRelax from '../assets/manRelax.jpg';
import preg from '../assets/preg_orig.jpg';
import pregRelax from '../assets/pregRelax.jpg';
import couples from '../assets/couplesMassage.jpg';
import book from '../assets/book.png';
import session from '../assets/session.png';
import YellowHand from '../assets/MBT Hand Logo-Light.png';

import r1 from '../assets/reviews/IMG_0703.JPEG';
import r2 from '../assets/reviews/IMG_0704.JPEG';
import r3 from '../assets/reviews/IMG_0705.JPEG';
import r4 from '../assets/reviews/IMG_0706.JPEG';
import r5 from '../assets/reviews/IMG_0707.JPEG';
import r6 from '../assets/reviews/IMG_0708.JPEG';
import r7 from '../assets/reviews/IMG_0709.JPEG';
import r8 from '../assets/reviews/Kelly Review.png';
import p1 from '../assets/reviews/preg/preg1.png';
import p2 from '../assets/reviews/preg/preg2.png';
import p3 from '../assets/reviews/preg/preg3.png';
import rr1 from '../assets/reviews/Relax/rr1.png';
import rr2 from '../assets/reviews/Relax/rr2.png';
import rr3 from '../assets/reviews/Relax/rr3.png';
import rr4 from '../assets/reviews/Relax/rr4.png';


function Content(props) {
    const user = props.userType;
    console.log(user);
    const [url, setURL] = useState("");
    const [isTant, setTant] = useState(true);
    const herTantURL = 'https://squareup.com/appointments/buyer/widget/xr32qt6eo7ras8/ZDHD02NX1T1NG.js';
    const herRelaxURL = "https://squareup.com/appointments/buyer/widget/x316iddfnvi6cn/ZDHD02NX1T1NG.js";
    const pregTantURL = 'https://squareup.com/appointments/buyer/widget/uzd48pufhtqvd7/ZDHD02NX1T1NG.js';
    const pregRelaxURL = 'https://squareup.com/appointments/buyer/widget/x8ng60j4puzda3/ZDHD02NX1T1NG.js';

    const myChange = (key) => {
        if (key === "2") {
            setTant(false)
        }
        else {
            setTant(true)
        }
    }

    useEffect(() => {
        //console.log(isTant)
        if (user === "her" && isTant) {
            setURL(herTantURL)
        }
        else if (user === "her" && !isTant) {
            setURL(herRelaxURL)
        }
        else if (user === "pregnant" && isTant) {
            setURL(pregTantURL)
        }
        else if (user === "pregnant" && !isTant) {
            setURL(pregRelaxURL)
        }
    }, [isTant]);
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = url;
        const x = document.getElementById('bookScript');
        script1.async = true;
        if (url === "")
            document.getElementById('bookScript').appendChild(script1);
        else {
            while (x.firstChild) {
                x.removeChild(x.lastChild);
            }
            x.appendChild(script1);
        }
    }, [url]);

    return (
        <div className='container'>
            <ConfigProvider theme={{
                components: {
                    Tabs: {
                        inkBarColor: "rgb(213, 187, 69)",
                        itemActiveColor: "rgb(213, 187, 69) !important",
                    }
                }
            }}>
                <Tabs
                    onTabClick={myChange}
                    defaultActiveKey="1"
                    tabPosition="top"
                    items={[
                        {
                            label: 'Tantra/Sensual',
                            key: '1',
                            children: <>
                                <div className='container'>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <img src={props.userType === "pregnant" ? preg : props.userType === "him" ? mantant : tantric} className="img-fluid" width="450" />
                                        </div>
                                        <div className="col-md-6 pt-4">
                                            <div>
                                                <h2>Desiring more from your massage?</h2>
                                                <Button><a href="#book" style={{ textDecoration: 'none' }}><b>BOOK NOW</b></a></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-md-12 pt-4">
                                            <p>Mobile Body Therapy is a traveling massage practice, who creates customized sessions for each and every client to fulfill all of their needs.&nbsp;
                                                {props.userType === "pregnant" ? "We specialize in Tantric, Yoni, Perineal and Prenatal massages to inform, guide, and provide an experience for pregnant women to connect, reconnect, and/or prepare their body for this special transition."
                                                    : props.userType === "him" ? "We specialize in Tantric Lingham Massage to inform, guide, and provide an experience for men to connect, or reconnect, with their body and approach sensuality from a place of positivity."
                                                        : props.userType === "them" ? "We specialize in Tantric Yoni & Lingham Massage to inform, guide, and provide an experience for women/men/couples to connect, or reconnect, with their bodies and approach sensuality from a place of positivity."
                                                            : "We specialize in Tantric Yoni Massage to inform, guide, and provide an experience for women to connect, or reconnect, with their body and approach their sensuality from a place of positivity and confidence."}
                                                <br />
                                                <img src={YellowHand} width="50" />
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row pt-1">
                                        <div className='d-flex justify-content-start mbtBlue pb-2'>
                                            <h3>How It Works</h3>
                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={consult} width="75" />
                                                <figcaption >1. Consultation &#40;Zoom/Call/Text&#41;<br />+<br />Meet Your Therapist</figcaption>
                                            </figure>

                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={book} width="75" />
                                                <figcaption >2. Book Your Appointment</figcaption>
                                            </figure>
                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={session} width="75" />
                                                <figcaption >3! Massage Session</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row pt-1'>
                                        <div className='d-flex justify-content-start mbtBlue pb-2'>
                                            <h3>Testamonials</h3>
                                        </div>
                                        <div className='col-md-12'>
                                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                                {user !== "pregnant" ? <>
                                                <ol class="carousel-indicators pt-4">
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="1"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="2"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="3"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="4"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="5"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="6"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="7"></li>
                                                </ol>
                                                <div className="carousel-inner text-center">
                                                    <div className="carousel-item active">
                                                        <img src={r1} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r2} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r3} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r4} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r5} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r6} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={r7} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item p-1">
                                                        <img src={r8} className="img-fluid" width="400" />
                                                    </div>
                                                </div>
                                            </> : <>
                                            <ol class="carousel-indicators pt-4">
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="1"></li>
                                                    <li data-bs-target="#carouselExampleControls" data-bs-slide-to="2"></li>
                                                </ol>
                                                <div className="carousel-inner text-center">
                                                    <div className="carousel-item active">
                                                        <img src={p1} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={p2} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={p3} className="img-fluid" width="400" />
                                                    </div>
                                                </div>
                                            </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        },
                        {
                            label: 'Relaxation',
                            key: '2',
                            children: <>
                                <div className='container'>
                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <img src={props.userType === "pregnant" ? pregRelax : props.userType === "him" ? manRelax : props.userType === "them" ? couples : relax} className="img-fluid" width="450" />
                                        </div>
                                        <div className="col-md-6 pt-4">
                                            <div>
                                                {user !== "pregnant" ? <h2>Reset Button for Your Body<br /></h2> : <h2>Prenatal Massage<br/></h2>}
                                                <Button><a href="#book" style={{ textDecoration: 'none' }}><b>BOOK NOW</b></a></Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-4">
                                        <div className="col-md-12">
                                            <p>Mobile Body Therapy is a traveling massage practice, who creates customized sessions for each and every client to fulfill all of their needs.&nbsp;
                                            {user === "pregnant" ? "Our Prenatal/Pregnancy Massage is designed to focus on the unique needs of our pregnant clients as your body changes throughout the various stages of your pregnancy."  : ""}
                                                <br />
                                                <img src={YellowHand} width="50" />
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <div className='d-flex justify-content-start mbtBlue'>
                                            <h3>How It Works</h3>
                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={consult} width="75" />
                                                <figcaption >1. Consultation &#40;Zoom/Call/Text&#41;<br />+<br />Meet Your Therapist</figcaption>
                                            </figure>

                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={book} width="75" />
                                                <figcaption >2. Book Your Appointment</figcaption>
                                            </figure>
                                        </div>
                                        <div className="col-sm-4">
                                            <figure>
                                                <img src={session} width="75" />
                                                <figcaption >3! Massage Session</figcaption>
                                            </figure>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row pt-1'>
                                        <div className='d-flex justify-content-start mbtBlue'>
                                            <h3>Testamonials</h3>
                                        </div>
                                        <div className='col-md-12'>
                                        <div id="carouselExampleControls2" className="carousel slide" data-bs-ride="carousel">
                                                {user !== "pregnant" ? <>
                                                <ol class="carousel-indicators pt-4">
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="0" class="active"></li>
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="1"></li>
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="2"></li>
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="3"></li>
                                                </ol>
                                                <div className="carousel-inner text-center">
                                                    <div className="carousel-item active">
                                                        <img src={rr1} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={rr2} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={rr3} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={rr4} className="img-fluid" width="400" />
                                                    </div>
                                                </div>
                                            </> : <>
                                            <ol class="carousel-indicators pt-4">
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="0" class="active"></li>
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="1"></li>
                                                    <li data-bs-target="#carouselExampleControls2" data-bs-slide-to="2"></li>
                                                </ol>
                                                <div className="carousel-inner text-center">
                                                    <div className="carousel-item active">
                                                        <img src={p1} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={p2} className="img-fluid" width="400" />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={p3} className="img-fluid" width="400" />
                                                    </div>
                                                </div>
                                            </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        },
                    ]} />
            </ConfigProvider>
            <div className='row pt-1' id='book'>
                <div className='d-flex justify-content-start mbtBlue'>
                    <h3>Book an Appointment</h3>
                </div>
                <div className='col-md-12 pt-2'>
                    <div id="bookScript"> </div>
                </div>
            </div>
            <hr />
        </div>
    )
}

export default Content