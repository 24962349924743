
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Choice from './pages/Choice';
import Her from './pages/Her';
import Him from './pages/Him';
import Them from './pages/Them';
import Preg from './pages/Preg';
import Volunteer from './pages/Volunteer';
import ReactGA from "react-ga4";

function App() {

  const TRACKING_ID = 'G-EJ79RTVCVP';
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: document.location.pathname });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={
            <Home />
          } />
          <Route exact path="/:cityName/for" element={
            <Choice />
          } />
          <Route exact path="/:cityName/her" element={
            <Her />
          } />
          <Route exact path="/:cityName/him" element={
            <Him />
          } />
          <Route exact path="/:cityName/them" element={
            <Them />
          } />
          <Route exact path="/:cityName/pregnancy-massage" element={
            <Preg />
          } />
          <Route exact path="/volunteer" element={
            <Volunteer />
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
