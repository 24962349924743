import React from 'react'
import DefaultLayout from '../components/DefaultLayout'

function Volunteer() {
  return (
    <DefaultLayout city={localStorage.getItem("city")}>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd4UEODo5jpaMERnacOk0jFn8zJKuLGHHDXkS7ZCUO9RfzJKQ/viewform?embedded=true" width="100%" height="1187" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
    </DefaultLayout>
  )
}

export default Volunteer