import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/MBT Hand Logo2 copy.png'
import Austin from '../assets/Austin.png';
import Dallas from '../assets/Dallas copy.png';
import Houston from '../assets/Houston.png';
import EP from '../assets/EP.png';
import mca from '../assets/mcallen.png';
import lr from '../assets/laredo.png';
import SA from '../assets/SA City.png';
import { Form, Input, Modal, Button } from 'antd';

function Home() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    function myCity(values) {
        //console.log(values)
        function hasWhiteSpace(s) {
            return /\s/g.test(s);
          }
          if (hasWhiteSpace(values.city) === true) {
            values.city = values.city.replaceAll(/\s/g, "")
          }
          window.location = `/${values.city}/for`
    }
    return (
        <div className="p-3">
            <div>
                <img src={Logo} className="img-fluid" width="275" />
                <h1 className='mbtBlue pt-3'>Select your location</h1>
            </div>

            <div className="vstack">
                <div>
                    <Link to={`/Austin/for`}>
                        <img id="atxSelect" src={Austin} className="img-fluid" width="225" />
                    </Link>
                </div>
                <div>
                    <Link to={`/Dallas-DFW/for`}>
                        <img id="DFWSelect" src={Dallas} className="img-fluid" width="200" />
                    </Link>
                </div>
                <div>
                    <Link to={`/Houston/for`}>
                        <img id="htxSelect" src={Houston} className="img-fluid" width="225" />
                    </Link>
                </div>
                <div>
                    <Link to={`/SanAntonio/for`}>
                        <img id="saSelect" src={SA} className="img-fluid" width="220" />
                    </Link>
                </div>
                <div>
                    <Link to={`/ELPaso/for`}>
                        <img src={EP} className="img-fluid" width="240" />
                    </Link>
                </div>
                <div>
                    <Link to={`/McAllen/for`}>
                        <img src={mca} className="img-fluid" width="230" />
                    </Link>
                </div>
                {/* <div>
                    <Link to={`/Laredo/for`}>
                        <img src={lr} className="img-fluid" width="240" />
                    </Link>
                </div> */}

                <div className='pt-3'>
                    <h2><a className='mbtYellow' onClick={showModal}>OTHER</a></h2>
                    <Modal title="Please Enter Your City Name" open={isModalOpen} onCancel={handleCancel} footer={[
                        <Button form="myCityForm" key="submit" htmlType="submit">
                            Go
                        </Button>
                    ]}>
                        <Form id='myCityForm' layout='horizontal' onFinish={myCity}>
                            <Form.Item name="city" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Home